import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default class Chemical extends Component {
        render() {
            const options = {
                loop: true,
                margin: 0,
                items: 1,
                autoplay: true,
                dots: true,
                autoplay: true,
                dots: true
            };    
        return (
            <div className="industries-page">
                <Helmet>
                    <title>Scritpics | Chemical</title>
                    <meta name="description"  content="Chemical" />
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:site" content="@Scripticstech"/>
                    <meta name="twitter:title" content="Scritpics | Chemical"/>
                    <meta name="twitter:description" content="Chemical"/>
                    <meta name="twitter:creator" content="@Scripticstech"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Scritpics | Chemical"/>
                    <meta property="og:description" content="Scriptics Chemical Industries"/>
                    <meta property="og:image" content="https://www.scriptics.ai/assets/img/og_meta.png"/>
                    <meta property="og:url" content="https://www.scriptics.ai/"/>
                    <meta name="twitter:image" content="https://www.scriptics.ai/assets/img/1674040738302.png" />
                    <meta name="twitter:domain" content="scriptics.ai"/>
                    <body id="page-industry" className="industry-health"/>
                </Helmet>
                <section className='srv-main'>
                    <OwlCarousel className="owl-theme" {...options} >
                        <div className='slide-1'>  
                            <div className='slide-img'>
                                <img src="/assets/img/Chemicals/AI.jpg" />
                                <div className='slider-overlay-amethyst'></div>
                            </div>
                            <div className='caption'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-7 col-md-7 col-sm-12 col-xs-12'>
                                            <div className='slide_content'>
                                                <h2 className="h2-slide white-color mb-xs-10">MAKE THE FIRST MOVE WITH STRATEGY & CONSULTING</h2>   
                                            </div>
                                        </div>
                                    </div> 
                                </div>     
                            </div>      
                        </div>
                        <div className='slide-2'>
                            <div className='slide-img'>
                                <img src="/assets/img/Chemicals/Interactive Design.jpg" />
                                <div className='slider-overlay-amethyst'></div>
                            </div>
                            <div className='caption'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-7 col-md-7 col-sm-12 col-xs-12'>
                                            <div className='slide_content'>
                                                <h2 className="h2-slide white-color mb-xs-10">INITIAL DESIGN WITH INTERACTIVE TECHNOLOGY</h2>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>    
                        </div>
                        <div className='slide-3'>
                            <div className='slide-img'>
                                <img src="/assets/img/Chemicals/Strategy and consulting.jpg" />
                                <div className='slider-overlay-amethyst'></div>
                            </div>
                            <div className='caption'>
                                <div className='container'>
                                    <div className='row'> 
                                        <div className='col-lg-7 col-md-7 col-sm-12 col-xs-12 fadeInUp  Wow from-bottom'>
                                            <div className='slide_content'>
                                                <h2 className="h2-slide white-color mb-xs-10">SCRIPTICS, AI FOR GAMING EXPERIENCE</h2>    
                                            </div>
                                        </div>
                                    </div> 
                                </div>    
                            </div>
                        </div>
                    </OwlCarousel>
                </section>
                <section className="srv-content">
                    <div className="container">
                        <div className="row">
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12' style={{padding:0}}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 fadeInUp  Wow from-right">
                                    <div className="indus-info">
                                        <h2 className="indus-title_1">REWRITING PARTNER EXPERIENCE THROUGH INNOVATION</h2>
                                    </div>
                                </div>
                            </div>    
                            <div className="col-12 col-sm-12 col-md-6 col-1g-6 fadeInUp  Wow from-right">
                                <div className="srv-info">
                                    <p>The chemical and process industry is transitioning from being product sellers to solution providers with constant changes in their landscape. Increased cost, reduced growth rate, and inefficient operations are bringing down their performance. Establishing a secure future with increasing revenues and reduced costs is the top priority of organizations. There is a need for players to join the digital revolution, build new business models, provide effective chemical industry consulting, establish process manufacturing software, and raise the bar for operational excellence.</p>
                                    <p>Our services help streamline processes, enhance production efficiency of plants and reduce environmental impact. We enable efficient operations management through effective cloud strategies, process manufacturing software, asset management, chemical manufacturing software, and Big Data analytics.</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-1g-6 fadeInUp  Wow from-left">
                                <img className="srv-con-img" src="/assets/img/Inner Images/chemical.jpg"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="srv-level">
                    <div className="container">
                        <div className="row">
                            <div className="srv-box col-12 col-sm-12 col-md-6 col-lg-6 fadeInUp  Wow from-bottom">
                                <div className="image_with_text">
                                    <img src="/assets/img/choice.svg" alt="industries level icon 1"/>
                                    <p>Our Digital & Analytics services helps design and build key platform components of customers’ Digital-Technology-Footprint to power the Digital transformation journey. </p>
                                </div>
                            </div>
                            <div className="srv-box col-12 col-sm-12 col-md-12 col-lg-6 fadeInUp  Wow from-bottom">
                                <div className="image_with_text">
                                    <img src="/assets/img/discover (1).svg" alt="industries level icon 2"/>
                                    <p>At IoT WoRKS we help create entirely new services that deliver measurable business outcomes. These experience will have an increasing role in differentiating enterprises</p>
                                </div>
                            </div>
                            <div className="srv-box col-12 col-sm-12 col-md-12 col-lg-6 fadeInUp  Wow from-bottom">
                                <div className="image_with_text">
                                    <img src="/assets/img/automation (1).svg" alt="industries level icon 3"/>
                                    <p>Our Applications business is structured, allowing us to offer clients a full lifecycle of systems integration consulting services and a unified approach to developing the right solutions for their business needs.</p>
                                </div>
                            </div>
                            <div className="srv-box col-12 col-sm-12 col-md-12 col-lg-6 fadeInUp  Wow from-bottom">
                                <div className="image_with_text">
                                    <img src="/assets/img/innovation.svg" alt="industries level icon 4"/>
                                    <p>Artificial intelligence-driven automation platform. It consists of machine learning components, automation modules, orchestration components, well-proven monitoring tools, knowledge management, and a reporting layer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="capability">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h2>CAPABILITIES</h2>
                            </div>
                            <div className="srv-capability-box col-12 col-lg-4 fadeInUp  Wow from-bottom">
                                <div className="capa_image_with_text">
                                    <div className="capa_img">
                                        <img src="/assets/img/robotic.svg" alt="capabilities icon 1"/>
                                        <p>Our Infrastructure Management Services has some of the most impressive credentials in the IT Vendor landscape. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="srv-capability-box col-12 col-lg-4 fadeInUp  Wow from-bottom">
                                <div className="capa_image_with_text">
                                    <div className="capa_img">
                                        <img src="/assets/img/Mask Group 2.svg" alt="capabilities icon 2"/>
                                        <p>Scriptics's capital market solution suite serves investment banks, prime brokerages, and dealers in alignment with current market standards. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="srv-capability-box col-12 col-lg-4 fadeInUp  Wow from-bottom">
                                <div className="capa_image_with_text">
                                    <div className="capa_img">
                                        <img src="/assets/img/Mask Group 5.svg" alt="capabilities icon 3"/>
                                        <p>We are one of the world’s leading and fastest growing global engineering services provider. From product blueprinting to support, from software or hardware to mechanical.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }    
}

